import React, { useContext } from "react";
import MotionElement from "../MotionElement/MotionElement";
import { Typography } from "@mui/material";
import { WindowDimensions } from "../../App";
import "../../screens/HomePage/HomePage.css";
import Slots from "./Slots";
import { useStoreState, IBooking } from "../../custom/store/store";
import { styles } from "./TimeSlot.styles";

interface ISelect {
  variant: string;
  bookedSlots?: IBooking[];
}

const TimeSlotPrivate = ({ variant, bookedSlots }: ISelect) => {
  const windowDimensions = useContext(WindowDimensions);
  const bookDetails = useStoreState((state) => state.bookDetails);
  let dateNow = new Date();

  const getTimeIndex = () => {
    if (
      `${dateNow.getDate()}/${
        dateNow.getMonth() + 1
      }/${dateNow.getFullYear()}` === bookDetails.date &&
      dateNow.getHours() >= 11
    ) {
      return dateNow.getHours() + 1;
    }
    return 11;
  };

  const renderSlots = (time: string) => {
    let slotArr = [];
    let status: "booked" | "available" | "unavailable" = "available";
    try {
      bookedSlots?.forEach((slot) => {
        if (slot.date === bookDetails.date && slot.pool === "common") {
          slot.timeArr.forEach((val) => {
            if (val.time === time) {
              status = "unavailable";
            }
          });
        }
      });
      bookedSlots?.forEach((slot) => {
        if (slot.date === bookDetails.date && slot.pool === bookDetails.pool) {
          slot.timeArr.forEach((val) => {
            if (val.time === time) {
              status = "booked";
            }
          });
        }
      });
    } catch (e) {
      // pass
    }

    slotArr.push(<Slots variant={variant} time={time} status={status} />);
    return slotArr;
  };

  const renderTimeFrame = () => {
    let timeFrameArr = [];
    for (let i = getTimeIndex(); i < 23; i++) {
      timeFrameArr.push(
        <div
          key={i}
          style={{
            ...styles.timeFrameDiv,
            width: windowDimensions.width < 500 ? "90vw" : "600px",
            flexDirection: "row",
          }}
        >
          <div style={styles.privatePoolDiv}>
            <Typography style={styles.typography}>
              {`${i > 12 ? i - 12 : i}:00 ${i >= 12 ? "PM" : "AM"}`}
            </Typography>
            <div
              style={{
                ...styles.timeFrameColumn,
                gridTemplateColumns: "auto",
              }}
            >
              {renderSlots(
                `${i > 12 ? i - 12 : i}:00 ${i >= 12 ? "PM" : "AM"}`
              )}
            </div>
          </div>
          <div style={styles.privatePoolDiv}>
            <Typography style={styles.typography}>
              {`${i > 12 ? i - 12 : i}:30 ${i >= 12 ? "PM" : "AM"}`}
            </Typography>
            <div
              style={{
                ...styles.timeFrameColumn,
                gridTemplateColumns: "auto",
              }}
            >
              {renderSlots(
                `${i > 12 ? i - 12 : i}:30 ${i >= 12 ? "PM" : "AM"}`
              )}
            </div>
          </div>
        </div>
      );
    }
    return timeFrameArr;
  };

  return (
    <MotionElement
      duration={1}
      hide={{ opacity: 0, x: 100 }}
      show={{ opacity: 1, x: 0 }}
      // reverse={reverse}
    >
      <div className="horizontalScroll" style={styles.timeSlotDiv}>
        {renderTimeFrame()}
      </div>
    </MotionElement>
  );
};
export default TimeSlotPrivate;
