import React, { useEffect, useState, useContext } from "react";

import { motion, useInView, useAnimation } from "framer-motion";
import { SentimentSatisfiedAltSharp } from "@mui/icons-material";
import { Typography } from "@mui/material";
import Footer from "../../components/Footer/Footer";
import MotionElement from "../../components/MotionElement/MotionElement";
import { WindowDimensions } from "../../App";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

export default function Confirmation() {
  const windowDimensions = useContext(WindowDimensions);

  const getSize = () => {
    return windowDimensions.width * 0.00052 > 0.5
      ? windowDimensions.width * 0.00052
      : 0.5;
  };

  let navigate = useNavigate();

  const home = () => {
    navigate("/home");
  };

  return (
    <>
      <MotionElement duration={500} hide={{ opacity: 0 }} show={{ opacity: 1 }}>
        <div
          style={{
            width: "100vw",
            height: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            background: "ghostwhite",
            transform: "translate(0px,1px)",
          }}
        >
          <Typography
            style={{
              fontSize: `${getSize() * 3}rem`,
              color: "#483D8B",
              fontFamily: "jost",
            }}
          >
            {"Booking Confirmed!"}
          </Typography>
          <Typography
            style={{
              fontSize: `${getSize() * 2}rem`,
              color: "#483D8B",
              fontFamily: "jost",
            }}
          >
            {"You'll get a confirmation email"}
          </Typography>
          {/* <SentimentSatisfiedAltSharp
            sx={{
              fontSize: "5rem",
              color: "#483D8B",
            }}
          /> */}
          <MotionElement
            duration={0.8}
            hide={{ opacity: 0, scale: 0 }}
            show={{ opacity: 1, scale: 1 }}
            delay={1}
          >
            <motion.button
              whileHover={{ scale: isMobile ? 0.9 : 1.2 }}
              transition={{
                // ease: "linear",
                duration: 1,
                type: "spring",
                bounce: 0.5,
              }}
              style={{
                // background: "red",
                width: `${windowDimensions.width / 8}px`,
                minWidth: "150px",
                maxWidth: "200px",
                height: "40px",
                background: "none",
                borderRadius: "30px",
                border: "3px solid #ff5d00",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "30px",
                cursor: isMobile ? "default" : "pointer",
              }}
              onClick={home}
            >
              <Typography
                style={{
                  fontSize: `1.2rem`,
                  color: "#ff5d00",
                  fontFamily: "jost",
                }}
              >
                {"Go to home"}
              </Typography>
            </motion.button>
          </MotionElement>
        </div>
      </MotionElement>
      <Footer />
    </>
  );
}
