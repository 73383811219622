import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./LoadingPage.css";
import { WindowDimensions } from "../../App";
import LoadingCircles from "../../components/Logo/LoadingCircles";

export default function LoadingPage() {
  const windowDimensions = useContext(WindowDimensions);
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      navigate("/home");
    }, 4000);
  }, []);

  return (
    <>
      <div className="loadingDiv">
        {loading && (
          <LoadingCircles
            color="white"
            loading={true}
            windowDimensions={windowDimensions}
          />
        )}
      </div>
    </>
  );
}
