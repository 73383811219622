import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import labels from "./Footer.labels.json";
import { motion } from "framer-motion";

import {
  Instagram,
  FacebookRounded,
  YouTube,
  LinkedIn,
  Mail,
  LocalPhoneRounded,
} from "@mui/icons-material";

export default function Footer() {
  return (
    <motion.div
      variants={{
        hidePage: { opacity: 0 },
        showPage: { opacity: 1 },
      }}
      initial="hidePage"
      animate="showPage"
      transition={{ duration: 1, delay: 0 }}
    >
      <div
        style={{
          width: "100vw",
          height: "20vh",
          background: "linear-gradient(to bottom,ghostwhite,darkseagreen",
          paddingBottom: "10%",
          // transform: "translate(0px,1px)",
        }}
      />
      <div
        style={{
          background: "darkseagreen",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          style={{
            fontSize: `1.2rem`,
            color: "#f5f5f5",
            fontFamily: "jost",
          }}
        >
          {labels.brandName}
        </Typography>
        <div
          style={{
            width: "80vw",
            // background: "red",
            display: "flex",
            justifyContent: "center",
            marginTop: "2%",
          }}
        >
          <Typography
            style={{
              fontSize: `1rem`,
              color: "#f5f5f5",
              fontFamily: "jost",
              textAlign: "center",
            }}
          >
            {labels.address}
          </Typography>
        </div>
        <div
          style={{
            width: "100vw",
            maxWidth: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            marginTop: "1%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Mail
              sx={{
                fontSize: "2rem",
                color: "#f5f5f5",
              }}
            />
            <Typography
              style={{
                fontSize: `1rem`,
                color: "#f5f5f5",
                fontFamily: "jost",
                marginLeft: "3%",
              }}
            >
              {labels.mail}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <LocalPhoneRounded
              sx={{
                fontSize: "2rem",
                color: "#f5f5f5",
              }}
            />
            <Typography
              style={{
                fontSize: `1rem`,
                color: "#f5f5f5",
                fontFamily: "jost",
              }}
            >
              {labels.contact}
            </Typography>
          </div>
        </div>
        <Typography
          style={{
            fontSize: `1.2rem`,
            color: "#f5f5f5",
            fontFamily: "jost",
            marginTop: "2%",
          }}
        >
          {labels.connect}
        </Typography>
        <div
          style={{
            width: "100vw",
            maxWidth: "500px",
            // background: "red",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            margin: "3% 0% 50px 0%",
          }}
        >
          <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
            <Instagram
              sx={{
                fontSize: "3rem",
                color: "#f5f5f5",
              }}
            />
          </a>
          <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
            <FacebookRounded
              sx={{
                fontSize: "3rem",
                color: "#f5f5f5",
              }}
            />
          </a>

          <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
            <YouTube
              sx={{
                fontSize: "3rem",
                color: "#f5f5f5",
              }}
            />
          </a>
          <a href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
            <LinkedIn
              sx={{
                fontSize: "3rem",
                color: "#f5f5f5",
              }}
            />
          </a>
        </div>
      </div>
    </motion.div>
  );
}
