/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { styles } from "./TimeSlot.styles";
import { useStoreActions, useStoreState } from "../../custom/store/store";

interface ISelect {
  variant: string;
  time: string;
  status?: "booked" | "available" | "unavailable";
}

const Slots = ({ variant, time, status = "available" }: ISelect) => {
  const setSelectedSlots = useStoreActions(
    (actions) => actions.setSelectedSlots
  );
  const selectedSlots = useStoreState((state) => state.selectedSlots);
  const bookDetails = useStoreState((state) => state.bookDetails);
  const setBookDetails = useStoreActions((actions) => actions.setBookDetails);
  const bookPageLoad = useStoreState((state) => state.bookPageLoad);
  const setBookPageLoad = useStoreActions((actions) => actions.setBookPageLoad);

  const [selected, setSelected] = useState(false);
  const [pool, setPool] = useState("");
  const mainControls = useAnimation();

  const returnColor = () => {
    if (status === "available") {
      return "hsl(195, 53, 79)";
    }
    if (status === "booked") {
      return "hsl(180, 100, 25)";
    }
    if (status === "unavailable") {
      return "hsl(348, 83, 47)";
    }
  };

  useEffect(() => {
    if (!bookPageLoad) {
      setBookPageLoad(true);
      setTimeout(() => {
        mainControls.start("initial");
      }, 1500);
    }
  }, []);

  useEffect(() => {
    if (selected) {
      mainControls.start("selected");
      return;
    }
    mainControls.start("initial");
  }, [selected]);

  useEffect(() => {
    if (selectedSlots === 0) {
      mainControls.start("initial");
    }
  }, [selectedSlots]);

  useEffect(() => {
    setTimeout(() => {
      setPool(variant);
    }, 200);
  }, [variant]);

  useEffect(() => {
    setSelected(false);
    mainControls.start("initial");
  }, [bookDetails.date]);

  useEffect(() => {
    if (!selected) {
      setSelectedSlots(-1);
      let timeArr = [...bookDetails.timeArr];
      timeArr.forEach((booking) => {
        if (booking.time === time) {
          booking.slots -= 1;
          return;
        }
      });
      timeArr = timeArr.filter((booking) => booking.slots > 0);
      setBookDetails({ ...bookDetails, timeArr });
    } else {
      if (bookDetails.timeArr.length === 0) {
        let timeArr = [{ time, slots: 1 }];
        setBookDetails({ ...bookDetails, timeArr });
        setSelectedSlots(1);
        return;
      }
      let timeArr = [...bookDetails.timeArr];
      let newSlot = true;
      timeArr.forEach((booking) => {
        if (booking.time === time) {
          booking.slots += 1;
          newSlot = false;
          return;
        }
      });
      if (newSlot) {
        timeArr.push({ time, slots: 1 });
      }
      setBookDetails({ ...bookDetails, timeArr });

      setSelectedSlots(1);
    }
  }, [selected]);

  return (
    <motion.div
      variants={{
        initial: {
          scale: 1,
          backgroundColor: returnColor(),
        },
        selected: {
          backgroundColor: ["hsl(195, 53, 79)", "hsl(207, 44, 49)"],
        },
      }}
      initial="initial"
      animate={mainControls}
      transition={{
        type: "spring",
        bounce: 0.5,
      }}
      style={{
        ...styles.slots,
        width: pool === "common" ? "50px" : "100%",
        cursor: status === "available" ? "pointer" : "not-allowed",
      }}
      onClick={() => {
        status === "available" && setSelected((p) => !p);
      }}
    />
  );
};
export default Slots;
