import React, { useEffect, useState, useContext } from "react";
import { useSpring, animated, easings } from "react-spring";
import { motion, useAnimation } from "framer-motion";
import { Typography } from "@mui/material";
import lables from "../HomePage.labels.json";
import { WindowDimensions } from "../../../App";

interface IScroll {
  size: number;
}

export default function Scroll({ size }: IScroll) {
  const windowDimensions = useContext(WindowDimensions);

  const [anim, setAnim] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [randNum, setRandNum] = useState(Math.floor(Math.random() * 4));

  const scrollControls = useAnimation();

  const { animate } = useSpring({
    from: { animate: 0 },
    animate: anim ? 1 : 0,
    loop: true,
    config: { duration: 1000, easing: easings.easeOutQuad },
  });

  useEffect(() => {
    setAnim(true);
    scrollControls.start("showScroll");
    setTimeout(() => {
      setTimeout(() => {
        setShowHint(true);
        scrollControls.start("showHint");
      }, 1000);
      scrollControls.start("hideScroll");
    }, 4000);
  }, []);
  return (
    <>
      {!showHint && (
        <motion.div
          style={{
            width: `${size}px`,
            height: `${size * 2}px`,
            border: "3px solid black",
            borderRadius: `${size}px`,
            display: "flex",
            justifyContent: "center",
          }}
          variants={{
            hideScroll: { opacity: 0 },
            showScroll: { opacity: 1 },
          }}
          initial="showScroll"
          animate={scrollControls}
          transition={{
            duration: 1,
            type: "spring",
            bounce: 0.5,
          }}
        >
          <animated.div
            style={{
              width: `${size / 2}px`,
              height: `${size / 2}px`,
              background: "black",
              borderRadius: `${size}px`,
              marginTop: "15%",
              transform: animate.to({
                range: [0, 0.5, 1],
                output: [
                  `translateY(0px)`,
                  `translateY(${(size * 2) / 1.8}px)`,
                  `translateY(0px)`,
                ],
              }),
            }}
          />
        </motion.div>
      )}
      <motion.div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        variants={{
          hideHint: { opacity: 0 },
          showHint: { opacity: 1 },
        }}
        initial="hideHint"
        animate={scrollControls}
        transition={{
          duration: 1,
          type: "spring",
          bounce: 0.5,
        }}
      >
        <Typography
          style={{
            fontSize: windowDimensions.width <= 500 ? "1rem" : "1.5rem",
            color: "black",
            fontFamily: "jost",
            textAlign: "center",
            marginTop: "3%",
          }}
        >
          {lables.scrollLabels[randNum]}
        </Typography>
      </motion.div>
    </>
  );
}
