
import labels from "../HomePage.labels.json";
import { Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import MotionElement from "../../../components/MotionElement/MotionElement";
import MultilineDescription from "../../../components/MultilineDescription/MultilineDescription";

interface ILaserBrief {
  size: number;
}

const LaserBrief = ({ size }: ILaserBrief) => {
  return (
    <div
      style={{
        marginBottom: "5%",
        width: "90vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MotionElement
        duration={0.8}
        delay={0.3}
        hide={{ opacity: 0, y: 100 }}
        show={{ opacity: 1, y: 0 }}
      >
        <Typography
          style={{
            fontSize: `${size * 5.5}rem`,
            color: "black",
            fontFamily: "jost",
            textAlign: "center",
            textUnderlinePosition: "auto",
          }}
        >
          {labels.descHeading}
        </Typography>
      </MotionElement>
      <MotionElement
        duration={0.8}
        delay={0.3}
        hide={{ opacity: 0, y: 100 }}
        show={{ opacity: 1, y: 0 }}
      >
        <>
          <Typography
            style={{
              fontSize: `${(size * 5.5) / 2}rem`,
              color: "black",
              fontFamily: "jost",
              textAlign: "center",
              marginTop: "3%",
            }}
          >
            {labels.description}
          </Typography>
          <MultilineDescription
            fontSize={`${(size * 5.5) / 3}rem`}
            // textAlign="left"
            multilineText={labels.multilineDescription}
          />
        </>
      </MotionElement>
      <MotionElement
        duration={0.8}
        delay={0.3}
        hide={{ opacity: 0, y: 100 }}
        show={{ opacity: 1, y: 0 }}
      >
        <div
          style={{
            display: "flex",
            marginTop: "2%",
            marginBottom: "10%",
            alignItems: "center",
            justifyContent: "center",
            width: "100vw",
          }}
        >
          <Info
            sx={{
              fontSize: "2rem",
              color: "steelblue",
            }}
          />
          <Typography
            style={{
              fontSize: `${(size * 5.5) / 3.5}rem`,
              color: "steelblue",
              fontFamily: "jost",
              textAlign: "center",
              marginLeft: "1%",
            }}
          >
            {labels.info}
          </Typography>
        </div>
      </MotionElement>
    </div>
  );
};
export default LaserBrief;
