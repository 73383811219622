import React, { useEffect, useState, useContext } from "react";

import { motion, useInView, useAnimation } from "framer-motion";
import { SentimentSatisfiedAltSharp } from "@mui/icons-material";
import { Typography } from "@mui/material";
import Footer from "../../components/Footer/Footer";
import MotionElement from "../../components/MotionElement/MotionElement";
import { WindowDimensions } from "../../App";

export default function Player() {
  const windowDimensions = useContext(WindowDimensions);

  const getSize = () => {
    return windowDimensions.width * 0.00052 > 0.5
      ? windowDimensions.width * 0.00052
      : 0.5;
  };

  return (
    <>
      <MotionElement duration={500} hide={{ opacity: 0 }} show={{ opacity: 1 }}>
        <div
          style={{
            width: "100vw",
            height: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            background: "ghostwhite",
            transform: "translate(0px,1px)",
          }}
        >
          <div
            style={{
              background: "#f5f5f5",
              boxShadow: " 8px 8px 12px 1px gainsboro",
              padding: "0% 5% 5% 5%",
              borderRadius: "2rem",

              // width: "50vw",
              // height: "50vh",
            }}
          >
            <Typography
              style={{
                fontSize: `${getSize() * 3}rem`,
                color: "#483D8B",
                fontFamily: "jost",
              }}
            >
              {"Anan_alx"}
            </Typography>
            <Typography
              style={{
                fontSize: `${getSize() * 1.5}rem`,
                color: "#483D8B",
                fontFamily: "jost",
              }}
            >
              {"Rating"}
            </Typography>
          </div>
        </div>
      </MotionElement>
      <Footer />
    </>
  );
}
