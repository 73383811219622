import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSpring, animated, easings } from "react-spring";
import { Typography } from "@mui/material";
import "./HomePage.css";
import { motion, useInView, useAnimation } from "framer-motion";
import Scroll from "./subComponents/Scroll";
import SR21 from "../../images/SniperGhost/SR21Ghost.jpg";
import Colt_m4 from "../../images/ColtM4/Colt-m4.jpg";
import AR15 from "../../images/AR15/AR15.jpg";
import CheckPointPro from "../../images/CheckPointPro.png";
import Collimator from "../../images/CollimatorSight.png";
import FirstAidKit from "../../images/FirstAidKitV2.png";
import HeadBand from "../../images/HeadBand.png";
import Target from "../../images/TargetV2.png";
import LogoLaserx from "../../images/logoLaserx.png";
import labels from "./HomePage.labels.json";
import ImageContainer from "./subComponents/ImageContainer";
import { isMobile, isSafari } from "react-device-detect";
import { NavigateNext, NavigateBefore } from "@mui/icons-material";
import LaserBrief from "./subComponents/LaserBrief";
import MotionElement from "../../components/MotionElement/MotionElement";
import Footer from "../../components/Footer/Footer";
import { WindowDimensions } from "../../App";

export default function HomePage() {
  const windowDimensions = useContext(WindowDimensions);

  let navigate = useNavigate();

  const [firstLoad, setFirstLoad] = useState(true);
  const [anim, setAnim] = useState(true);
  const [reverse, setReverse] = useState(false);

  const motionRef = useRef(null);
  const targetRef = useRef<HTMLDivElement>(null);

  const isInView = useInView(motionRef, {});

  const mainControls = useAnimation();

  const { animate } = useSpring({
    from: { animate: 1 },
    animate: anim ? 1 : 0,
    config: { duration: 500, easing: easings.linear },
  });

  const getSize = () => {
    return windowDimensions.width * 0.00052 > 0.5
      ? windowDimensions.width * 0.00052
      : 0.5;
  };

  useEffect(() => {
    setFirstLoad(false);
    if (isInView) {
      mainControls.start("hidden");
      return;
    }
    if (firstLoad) {
      mainControls.start("visibleScroll");
    }
    mainControls.start("visible");
  }, [isInView]);

  return (
    <>
      <animated.div
        style={{
          width: "100%",
          background: "#ff5d00",
          display: "flex",
          flexDirection: "column",
          opacity: animate,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <motion.div
            variants={{
              hidden: { opacity: 0, y: -100 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="visible"
            animate={mainControls}
            transition={{ duration: 0.5 }}
          >
            <img
              src={LogoLaserx}
              alt=""
              height={
                windowDimensions.width * 0.00052 > 0.5
                  ? windowDimensions.width * 0.00052 * 450
                  : 220
              }
            />
          </motion.div>
          <motion.div
            variants={{
              hidden: { opacity: 0, y: -50 },
              visibleScroll: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{
              duration: 0.8,
              type: "spring",
              bounce: 0.5,
            }}
            style={{
              position: "absolute",
              bottom: "10%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Scroll size={25} />
          </motion.div>
        </div>
        <div
          style={{
            width: "100vw",
            height: "20vh",
            background: "linear-gradient(to bottom,#ff5d00,white",
            paddingTop: "10%",
            transform: "translate(0px,2px)",
          }}
        />

        <div
          ref={motionRef}
          style={{
            width: "100vw",
            // height: "100vh",
            background: "linear-gradient(to bottom,white,ghostwhite",
            // background: "ghostwhite",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            // marginTop: "5%",
            transform: "translate(0px,1px)",
          }}
        >
          <MotionElement
            duration={1}
            delay={0.5}
            hide={{ opacity: 0, x: -100 }}
            show={{ opacity: 1, x: 0 }}
            reverse={reverse}
          >
            <div
              style={{
                display: "flex",
                background: "white",
                marginTop: windowDimensions.width < 500 ? "25%" : "10%",
              }}
            >
              <Typography
                style={{
                  fontSize: `${getSize() * 3.5}rem`,
                  color: "#0892d0",
                  fontFamily: "jost",
                  textAlign: "center",
                }}
              >
                {labels.catchPhrase.multilineDescription[0]}
              </Typography>
            </div>
          </MotionElement>
          <MotionElement
            duration={1}
            delay={0.5}
            hide={{ opacity: 0, x: 100 }}
            show={{ opacity: 1, x: 0 }}
            reverse={reverse}
          >
            <div
              style={{
                display: "flex",
                background: "white",
                marginTop: windowDimensions.width < 500 ? "15%" : "5%",
              }}
            >
              <Typography
                style={{
                  fontSize: `${getSize() * 3.5}rem`,
                  color: "#7df9ff",
                  fontFamily: "jost",
                  textAlign: "center",
                }}
              >
                {labels.catchPhrase.multilineDescription[1]}
              </Typography>
            </div>
          </MotionElement>
          <MotionElement
            duration={1}
            delay={0.5}
            hide={{ opacity: 0, scale: 0.8 }}
            show={{ opacity: 1, scale: 1 }}
            reverse={reverse}
          >
            <div
              style={{
                display: "flex",
                marginTop: windowDimensions.width < 500 ? "15%" : "10%",
              }}
            >
              <Typography
                style={{
                  fontSize: `${getSize() * 2.5}rem`,
                  color: "darkseagreen",
                  fontFamily: "jost",
                  textAlign: "center",
                }}
              >
                {labels.catchPhrase.multilineDescription[2]}
              </Typography>
            </div>
          </MotionElement>
          <MotionElement
            duration={1}
            delay={0.5}
            hide={{ opacity: 0, scale: 0.8 }}
            show={{ opacity: 1, scale: 1 }}
            reverse={reverse}
          >
            <motion.button
              whileHover={{ scale: isMobile ? 0.9 : 1.2 }}
              transition={{
                type: "spring",
                bounce: 0.5,
              }}
              style={{
                // background: "red",
                width: `${windowDimensions.width / 8}px`,
                minWidth: "150px",
                // height: "5rem",
                background: "none",
                borderRadius: "30px",
                border: "3px solid #ff5d00",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20%",
                cursor: isMobile ? "default" : "pointer",
              }}
              onClick={() => {
                setReverse(true);
                setTimeout(() => {
                  setAnim(false);
                  setTimeout(() => {
                    navigate("/book");
                  }, 500);
                }, 600);
              }}
            >
              <Typography
                style={{
                  fontSize: `${getSize() * 2.5}rem`,
                  color: "#ff5d00",
                  fontFamily: "jost",
                }}
              >
                {labels.book}
              </Typography>
            </motion.button>
          </MotionElement>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // background: "red",
            }}
          >
            <MotionElement
              duration={1}
              delay={0.5}
              hide={{ opacity: 0, y: 100 }}
              show={{ opacity: 1, y: 0 }}
              reverse={reverse}
            >
              <div
                style={{
                  marginTop: "20%",
                }}
              >
                <Typography
                  style={{
                    fontSize: `${getSize() * 5.5}rem`,
                    color: "black",
                    fontFamily: "jost",
                  }}
                >
                  {labels.heading}
                </Typography>
              </div>
            </MotionElement>

            <div
              ref={targetRef}
              className="horizontalScroll"
              style={{
                paddingLeft:
                  windowDimensions.width < 750
                    ? "25%"
                    : isSafari
                    ? "40%"
                    : "60%",
                display: "flex",
                width: "100vw",
                marginTop: "5%",
                overflowX: "scroll",
                scrollSnapType: "x mandatory",
                alignItems: "center",
                marginBottom: "5%",
                // background: "blue",
              }}
              onMouseEnter={() => {
                if (targetRef.current) {
                  if (targetRef.current.scrollLeft !== 0) {
                    mainControls.start("showScrollLeft");
                  }
                  if (
                    targetRef.current.scrollLeft !==
                    targetRef.current.scrollWidth -
                      targetRef.current.clientWidth
                  ) {
                    mainControls.start("showScrollRight");
                  }
                }
              }}
              onMouseLeave={() => {
                mainControls.start("hideScrollRight");
                mainControls.start("hideScrollLeft");
              }}
              onScroll={() => {
                if (targetRef.current) {
                  if (
                    targetRef.current.scrollLeft -
                      (targetRef.current.scrollWidth -
                        targetRef.current.clientWidth) <=
                      1 &&
                    targetRef.current.scrollLeft -
                      (targetRef.current.scrollWidth -
                        targetRef.current.clientWidth) >=
                      -1
                  ) {
                    mainControls.start("hideScrollRight");
                  } else {
                    mainControls.start("showScrollRight");
                  }
                  if (targetRef.current.scrollLeft === 0) {
                    mainControls.start("hideScrollLeft");
                  } else {
                    mainControls.start("showScrollLeft");
                  }
                }
              }}
            >
              {!isMobile && (
                <>
                  <motion.div
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50px",
                      position: "absolute",
                      right: "3%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 2,
                    }}
                    variants={{
                      hideScrollRight: {
                        opacity: 0,
                        scale: 0.8,
                        background: "hsl(0, 0%, 0%,0.2)",
                      },
                      showScrollRight: {
                        opacity: 1,
                        scale: 1,
                        background: "hsl(0, 0%, 0%,0.2)",
                      },
                      focusScrollRight: { background: "hsl(0, 0%, 0%,0.4)" },
                    }}
                    initial="hideScrollRight"
                    animate={mainControls}
                    transition={{ duration: 0.6, delay: 0 }}
                    onClick={() => {
                      if (targetRef.current) {
                        targetRef.current.scroll({
                          left: targetRef.current.scrollLeft + 400,
                          behavior: "smooth",
                        });
                      }
                    }}
                    onMouseEnter={() => {
                      mainControls.start("focusScrollRight");
                    }}
                    onMouseLeave={() => {
                      if (targetRef.current) {
                        if (
                          targetRef.current.scrollLeft ===
                          targetRef.current.scrollWidth -
                            targetRef.current.clientWidth
                        ) {
                          return;
                        }
                      }
                      mainControls.start("showScrollRight");
                    }}
                  >
                    <NavigateNext
                      sx={{
                        fontSize: "3rem",
                        color: "#f5f5f5",
                      }}
                    />
                  </motion.div>
                  <motion.div
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50px",
                      position: "absolute",
                      left: "3%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      zIndex: 2,
                    }}
                    variants={{
                      hideScrollLeft: {
                        opacity: 0,
                        scale: 0.8,
                        background: "hsl(0, 0%, 0%,0.2)",
                      },
                      showScrollLeft: {
                        opacity: 1,
                        scale: 1,
                        background: "hsl(0, 0%, 0%,0.2)",
                      },
                      focusScrollLeft: { background: "hsl(0, 0%, 0%,0.4)" },
                    }}
                    initial="hideScrollLeft"
                    animate={mainControls}
                    transition={{ duration: 0.6, delay: 0 }}
                    onClick={() => {
                      if (targetRef.current) {
                        targetRef.current.scroll({
                          left: targetRef.current.scrollLeft - 400,
                          behavior: "smooth",
                        });
                      }
                    }}
                    onMouseEnter={() => {
                      mainControls.start("focusScrollLeft");
                    }}
                    onMouseLeave={() => {
                      if (targetRef.current) {
                        if (targetRef.current.scrollLeft === 0) {
                          return;
                        }
                      }
                      mainControls.start("showScrollLeft");
                    }}
                  >
                    <NavigateBefore
                      sx={{
                        fontSize: "3rem",
                        color: "#f5f5f5",
                      }}
                    />
                  </motion.div>
                </>
              )}
              <ImageContainer
                delay={500}
                product={"AR15"}
                windowDimensions={windowDimensions}
              >
                <img
                  src={AR15}
                  alt=""
                  height={
                    windowDimensions.width * 0.00052 > 0.5
                      ? windowDimensions.width * 0.00052 * 150
                      : 100
                  }
                />
              </ImageContainer>
              <ImageContainer
                delay={isMobile && windowDimensions.width < 600 ? 0 : 700}
                product={"ColtM4"}
                windowDimensions={windowDimensions}
              >
                <img
                  src={Colt_m4}
                  alt=""
                  height={
                    windowDimensions.width * 0.00052 > 0.5
                      ? windowDimensions.width * 0.00052 * 150
                      : 100
                  }
                />
              </ImageContainer>
              <ImageContainer
                delay={windowDimensions.width > 1200 ? 900 : 0}
                product={"SR21Ghost"}
                windowDimensions={windowDimensions}
              >
                <img
                  src={SR21}
                  alt=""
                  height={
                    windowDimensions.width * 0.00052 > 0.5
                      ? windowDimensions.width * 0.00052 * 150
                      : 100
                  }
                />
              </ImageContainer>
              <ImageContainer
                delay={0}
                product={"HeadBand"}
                windowDimensions={windowDimensions}
              >
                <img
                  src={HeadBand}
                  alt=""
                  height={
                    windowDimensions.width * 0.00052 > 0.5
                      ? windowDimensions.width * 0.00052 * 150
                      : 100
                  }
                />
              </ImageContainer>
              <ImageContainer
                delay={0}
                product={"Target"}
                windowDimensions={windowDimensions}
              >
                <img
                  src={Target}
                  alt=""
                  height={
                    windowDimensions.width * 0.00052 > 0.5
                      ? windowDimensions.width * 0.00052 * 150
                      : 100
                  }
                />
              </ImageContainer>
              <ImageContainer
                delay={0}
                product={"FirstAidKit"}
                windowDimensions={windowDimensions}
              >
                <img
                  src={FirstAidKit}
                  alt=""
                  height={
                    windowDimensions.width * 0.00052 > 0.5
                      ? windowDimensions.width * 0.00052 * 150
                      : 100
                  }
                />
              </ImageContainer>
              <ImageContainer
                delay={0}
                product={"CheckPoint"}
                windowDimensions={windowDimensions}
              >
                <img
                  src={CheckPointPro}
                  alt=""
                  height={
                    windowDimensions.width * 0.00052 > 0.5
                      ? windowDimensions.width * 0.00052 * 150
                      : 100
                  }
                />
              </ImageContainer>
              <ImageContainer
                delay={0}
                product={"Collimator"}
                windowDimensions={windowDimensions}
              >
                <img
                  src={Collimator}
                  alt=""
                  height={
                    windowDimensions.width * 0.00052 > 0.5
                      ? windowDimensions.width * 0.00052 * 150
                      : 100
                  }
                />
              </ImageContainer>
              {windowDimensions.width > 750 && (
                <ImageContainer
                  delay={0}
                  product={"Collimator"}
                  windowDimensions={windowDimensions}
                  opacity={0}
                >
                  <div />
                </ImageContainer>
              )}
            </div>
          </div>
          <LaserBrief size={getSize()} />
        </div>
      </animated.div>
      <Footer />
    </>
  );
}
