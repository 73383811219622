import React, { useEffect, useState } from "react";
import { useSpring, animated, easings } from "react-spring";
import AnimatedCircle from "./AnimatedCircle";
import Logo from "./Logo";
import LogoLaserx from "../../images/logoLaserx.png";
import { isSafari } from "react-device-detect";

interface IHomePage {
  color: string;
  loading: boolean;
  windowDimensions: { width: number; height: number };
}

export default function LoadingCircles({
  color = "black",
  loading = false,
  windowDimensions,
}: IHomePage) {
  const [anim, setAnim] = useState(false);

  const circlesArr1 = [1.1, 2, 2.6, 2, 1.1];
  const circlesArr2 = [1.1, 2, 1.1];
  const circlesArr3 = [2.6, 2, 1.1, 2, 2.6];

  const { animate } = useSpring({
    from: { animate: 0 },
    animate: anim ? 1 : 0,
    config: { duration: 1500, easing: easings.linear },
  });

  useEffect(() => {
    setTimeout(() => {
      loading && setAnim(true);
    }, 2500);
  }, []);

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          //   background: "red",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <animated.div
          style={{
            width: `200${
              windowDimensions.width > windowDimensions.height ? "vw" : "vh"
            }`,
            height: `200${
              windowDimensions.width > windowDimensions.height ? "vw" : "vh"
            }`,
            background: "#ff5d00",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: `200${
              windowDimensions.width > windowDimensions.height ? "vw" : "vh"
            }`,
            position: "absolute",
            opacity: animate.to({ range: [0, 0.3, 1], output: [0, 0, 1] }),
            transform: animate.to({
              range: [0, 0.3, 1],
              output: ["scale(0.1)", "scale(0.1)", "scale(1)"],
            }),
          }}
        >
          <animated.div
            style={{
              transform: animate.to({
                range: [0, 1],
                output: ["scale(0.8)", "scale(1)"],
              }),
            }}
          >
            <img
              src={LogoLaserx}
              alt=""
              height={
                windowDimensions.width * 0.00052 > 0.5
                  ? windowDimensions.width * 0.00052 * 450
                  : 220
              }
            />
          </animated.div>
        </animated.div>
      </div>
      <animated.div
        style={{
          width: "16rem",
          height: "16rem",
          border: `.5rem solid ${color}`,
          borderRadius: "15rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          opacity: animate.to({ range: [0, 1], output: [1, 0] }),
          transform: animate.to({
            range: [0, 0.3, 1],
            output: [
              `scale(${
                windowDimensions.width * 0.00052 > 0.5
                  ? windowDimensions.width * 0.00052
                  : 0.5
              })`,
              "scale(0)",
              "scale(100)",
            ],
          }),
        }}
      >
        {/* Row 1 */}

        <div
          style={{
            width: "11rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "10%",
          }}
        >
          {circlesArr1.map((cir) => (
            <AnimatedCircle cir={cir} color={color} loading={loading} />
          ))}
        </div>

        {/* Row 2*/}

        <div
          style={{
            width: "11rem",
            display: "flex",
            justifyContent: "center",
            position: "relative",
            top: "-1%",
          }}
        >
          <AnimatedCircle
            cir={2}
            color={color}
            left="-4%"
            position="absolute"
            loading={loading}
          />

          {circlesArr2.map((cir) => (
            <AnimatedCircle
              cir={cir}
              color={color}
              margin="5%"
              loading={loading}
            />
          ))}
          <AnimatedCircle
            cir={2}
            color={color}
            right="-4%"
            position="absolute"
            loading={loading}
          />
        </div>

        {/* Row 3 */}

        <div
          style={{
            width: "12.6rem",
            //   border: "1px solid black",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
            top: "-5%",
          }}
        >
          {circlesArr3.map((cir) => (
            <AnimatedCircle cir={cir} color={color} loading={loading} />
          ))}
        </div>

        {/* Row 4 */}

        <div
          style={{
            width: "11rem",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            position: "relative",
            top: "-8%",
          }}
        >
          <AnimatedCircle
            cir={2}
            color={color}
            left="-4%"
            position="absolute"
            loading={loading}
          />
          {circlesArr2.map((cir) => (
            <AnimatedCircle
              cir={cir}
              color={color}
              margin="5%"
              loading={loading}
            />
          ))}
          <AnimatedCircle
            cir={2}
            color={color}
            right="-4%"
            position="absolute"
            loading={loading}
          />
        </div>

        {/* Row 5 */}

        <div
          style={{
            width: "11rem",
            //   border: "1px solid black",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "-8%",
          }}
        >
          {circlesArr1.map((cir) => (
            <AnimatedCircle cir={cir} color={color} loading={loading} />
          ))}
        </div>
      </animated.div>
    </>
  );
}
