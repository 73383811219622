import React, { useEffect, useState } from "react";
import MotionElement from "../../../components/MotionElement/MotionElement";
import TimeSlot from "../../../components/TimeSlot/TimeSlot";
import { Typography } from "@mui/material";
import { styles } from "../Booking.styles";
import labels from "../Booking.labels.json";
import { IBooking } from "../../../custom/store/store";

interface ICommon {
  selected: string;
  bookedSlots?: IBooking[];
}

const Common = ({ selected, bookedSlots }: ICommon) => {
  const [reverse, setReverse] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (selected === "common") {
      setTimeout(() => {
        setShow(true);
      }, 300);
    }

    if (selected === "private") {
      setReverse(true);
      setTimeout(() => {
        setShow(false);
      }, 300);
      return;
    }
    setReverse(false);
  }, [selected]);
  return (
    <>
      {show && (
        <MotionElement
          duration={1}
          hide={{ opacity: 0, x: 100 }}
          show={{ opacity: 1, x: 0 }}
          // delay={2}
          reverse={reverse}
        >
          <>
            <div
              style={{
                // width: "100vw",
                // background: "green",
                display: "flex",
                justifyContent: "center",
                //   alignItems: "center",
                flexDirection: "column",
              }}
            >
              <TimeSlot variant={selected} bookedSlots={bookedSlots} />
            </div>
            <Typography
              style={{
                ...styles.typography,
                marginTop: "5px",
                fontSize: ".8rem",
              }}
            >
              {`${labels.slotsCommon}`}
            </Typography>
          </>
        </MotionElement>
      )}
    </>
  );
};
export default Common;
