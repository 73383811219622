import React, { useEffect, useState, useRef } from "react";
import { useSpring, animated, easings } from "react-spring";
import { useInView, motion, useAnimation } from "framer-motion";
import labels from "../HomePage.labels.json";
import { Typography } from "@mui/material";
import { isMobile, isChrome, isSafari } from "react-device-detect";

interface IImageContainer {
  children: JSX.Element;
  delay: number;
  product: string;
  opacity?: number;
  windowDimensions: {
    width: number;
    height: number;
  };
}

interface IProduct {
  ColtM4: { multilineDescription: string[] };
  AR15: { multilineDescription: string[] };
  SR21Ghost: { multilineDescription: string[] };
  FirstAidKit: { multilineDescription: string[] };
  HeadBand: { multilineDescription: string[] };
  Target: { multilineDescription: string[] };
  CheckPoint: { multilineDescription: string[] };
  Collimator: { multilineDescription: string[] };
}

const ImageContainer = ({
  children,
  delay,
  product,
  windowDimensions,
  opacity = 1,
}: IImageContainer) => {
  const [anim, setAnim] = useState(false);
  const [animZoom, setAnimZoom] = useState(false);
  const [animExpand, setAnimExpand] = useState(false);
  const [expandDetails, setExpandDetails] = useState(false);

  const motionRef = useRef<HTMLDivElement>(null);
  const heightRef: any = useRef(null);

  const isInView = useInView(motionRef, {});

  const mainControls = useAnimation();
  const descControls = useAnimation();

  const { animate } = useSpring({
    from: { animate: 0 },
    animate: anim ? 1 : 0,
    config: { duration: 500, easing: easings.easeOutQuad },
  });

  const { zoom } = useSpring({
    from: { zoom: 0 },
    zoom: animZoom ? 1 : 0,
    config: { duration: 300, easing: easings.easeInOutSine },
  });

  const { expand } = useSpring({
    from: { expand: 0 },
    expand: animExpand ? 1 : 0,
    config: { duration: 300, easing: easings.easeInOutSine },
  });

  const getHeight = () => {
    if (heightRef?.current?.offsetHeight) {
      if (heightRef?.current?.offsetHeight + 250 > 380) {
        return heightRef?.current?.offsetHeight + 250;
      }
    }
    return 380;
  };

  useEffect(() => {
    if (expandDetails) {
      descControls.start("showDetails");
      setAnimExpand(true);
      return;
    }
    descControls.start("hideDetails");
    setAnimExpand(false);
  }, [expandDetails]);

  useEffect(() => {
    if (isInView) {
      setTimeout(() => {
        setAnim(true);
        mainControls.start("showDetails");
      }, delay);
      return;
    }
    // setAnim(false);
  }, [isInView]);

  return (
    <animated.div
      onMouseEnter={() => {
        !isMobile && setAnimZoom(true);
      }}
      onMouseLeave={() => {
        !isMobile && setAnimZoom(false);
      }}
      ref={motionRef}
      style={{
        scrollSnapAlign: isMobile || !isChrome ? "center" : "",
        margin: isMobile ? "0% 5% 0% 0%" : "3%",
        marginRight:
          product === "Collimator" && isSafari
            ? "6%"
            : isMobile && windowDimensions.width < 500
            ? "25%"
            : "3%",
        paddingRight: isMobile ? "10%" : "0%",
        opacity: opacity,
        transform: zoom.to({
          range: [0, 1],
          output: [
            `${windowDimensions.width < 1200 ? "scale(0.8)" : "scale(1)"}`,
            `${windowDimensions.width < 1200 ? "scale(0.9)" : "scale(1.05)"}`,
          ],
        }),
      }}
    >
      <animated.div
        style={{
          background: "#f5f5f5",
          boxShadow: " 8px 8px 12px 1px gainsboro",
          padding: "5%",
          width:
            windowDimensions.width < 420
              ? "90vw"
              : `${windowDimensions.width / 4}px`,
          height: expand.to({
            range: [0, 1],
            output: ["380px", `${getHeight()}`],
          }),
          overflow: "hidden",
          minWidth: "315px",
          WebkitBackdropFilter: "blur(1rem)",
          borderRadius: "2rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          opacity: animate.to({
            range: [0, 1],
            output: [0, 1],
          }),
          transform: animate.to({
            range: [0, 1],
            output: ["scale(0.5)", "scale(1)"],
          }),
        }}
      >
        <Typography
          style={{
            fontSize: `2rem`,
            color: "black",
            fontFamily: "jost",
          }}
        >
          {product}
        </Typography>
        {children}
        <div ref={heightRef}>
          {labels[product as keyof IProduct].multilineDescription.map(
            (desc: string, index: number) => {
              return (
                <motion.div
                  style={{ width: "80%" }}
                  variants={{
                    hideDetails: { opacity: 0, y: -50 },
                    showDetails: { opacity: 1, y: 0 },
                  }}
                  initial={"hideDetails"}
                  animate={index >= 2 ? descControls : mainControls}
                  transition={{
                    duration: 0.3,
                    delay: index >= 2 ? index / 10 : 0.3 + index / 10,
                  }}
                >
                  <ul key={index} style={{ fontFamily: "jost" }}>
                    {desc}
                  </ul>
                </motion.div>
              );
            }
          )}
        </div>
        {labels[product as keyof IProduct].multilineDescription.length > 2 && (
          <motion.div
            style={{
              position: "absolute",
              bottom: "5%",
              left: "40%",
            }}
            variants={{
              hideDetails: { opacity: 0, y: -50 },
              showDetails: { opacity: 1, y: 0 },
            }}
            initial="hideDetails"
            animate={mainControls}
            transition={{ duration: 0.3, delay: 0.7 }}
            onClick={() => {
              if (expandDetails) {
                descControls.start("hideDetails");
                setTimeout(() => {
                  setExpandDetails((p) => !p);
                }, 100 * (labels[product as keyof IProduct].multilineDescription.length + 2));
                return;
              }
              setExpandDetails((p) => !p);
            }}
          >
            <Typography
              style={{
                fontSize: `1rem`,
                color: "steelblue",
                fontFamily: "jost",
                cursor: isMobile ? "default" : "pointer",
              }}
            >
              {expandDetails ? labels.showLess : labels.showMore}
            </Typography>
          </motion.div>
        )}
      </animated.div>
    </animated.div>
  );
};
export default ImageContainer;
