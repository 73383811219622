import { MotionStyle } from "framer-motion";
import React from "react";

interface IMotionStyles {
  [x: string]: MotionStyle;
}

interface IStyles {
  [x: string]: React.CSSProperties;
}

export const motionStyles: IMotionStyles = {
  content: {
    width: "100vw",
    height: "90vh",
    minHeight: "660px",
    background: "linear-gradient(to bottom,white,ghostwhite",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    transform: "translate(0px,1px)",
  },

  card: {
    background: "#f5f5f5",
    boxShadow: " 8px 8px 12px 1px gainsboro",
    height: "180px",
    overflow: "hidden",
    minWidth: "315px",
    WebkitBackdropFilter: "blur(1rem)",
    borderRadius: "2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  dateCard: {
    background: "#f5f5f5",
    boxShadow: " 8px 6px 12px 1px gainsboro",
    overflow: "hidden",
    WebkitBackdropFilter: "blur(1rem)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100px",
  },
};

export const styles: IStyles = {
  heading: {
    fontSize: `2rem`,
    color: "black",
    fontFamily: "jost",
  },
  typography: {
    fontSize: `1rem`,
    color: "black",
    fontFamily: "jost",
    textAlign: "center",
  },
  slotsTypography: {
    fontSize: `.8rem`,
    color: "black",
    fontFamily: "jost",
    margin: "10px 0% 10px 0%",
  },
  slotsAvailabilityDiv: {
    width: "10px",
    height: "10px",
    borderRadius: "3px",
    marginRight: "5px",
  },
  slotsDiv: {
    display: "flex",
    alignItems: "center",
    margin: "0% 2% 0% 2%",
  },
  slotsContainer: {
    width: "90vw",
    display: "flex",
    justifyContent: "center",
  },
  poolContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "30vh",
    width: "100vw",
    marginTop: "20px",
  },
  button: {
    minWidth: "150px",
    maxWidth: "200px",
    height: "40px",
    background: "none",
    borderRadius: "30px",
    border: "3px solid #ff5d00",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
  },
};
