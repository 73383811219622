import React, { useEffect, useState } from "react";
import MotionElement from "../../../components/MotionElement/MotionElement";
import TimeSlotPrivate from "../../../components/TimeSlot/TimeSlotPrivate";
import { Typography } from "@mui/material";
import { styles } from "../Booking.styles";
import labels from "../Booking.labels.json";
import { IBooking } from "../../../custom/store/store";

interface IPrivate {
  selected: "private" | "common" | "none";
  bookedSlots?: IBooking[];
}

const Private = ({ selected, bookedSlots }: IPrivate) => {
  const [reverse, setReverse] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (selected === "private") {
      setTimeout(() => {
        setShow(true);
      }, 300);
    }
    if (selected === "common") {
      setReverse(true);
      setTimeout(() => {
        setShow(false);
      }, 300);
      return;
    }
    setReverse(false);
  }, [selected]);
  return (
    <>
      {show && (
        <MotionElement
          duration={1}
          hide={{ opacity: 0, x: -200 }}
          show={{ opacity: 1, x: 0 }}
          reverse={reverse}
        >
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TimeSlotPrivate variant={selected} bookedSlots={bookedSlots}/>
            </div>
            <Typography
              style={{
                ...styles.typography,
                marginTop: "5px",
                fontSize: ".8rem",
              }}
            >
              {`${labels.slotsPrivate}`}
            </Typography>
          </>
        </MotionElement>
      )}
    </>
  );
};
export default Private;
