/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSpring, animated, easings } from "react-spring";

interface IWave {
  themeColor?: string;
}

export const Wave = ({ themeColor }: IWave) => {
  const [waveArray, setWaveArray] = useState<JSX.Element[]>([]);
  const [wave, setWave] = useState(0);

  const { animate } = useSpring({
    from: { animate: 0 },
    animate: wave ? 1 : 0,
    loop: true,
    config: { duration: 1200, easing: easings.easeOutQuad },
  });

  useEffect(() => {
    setWave(1);
  }, []);

  const waveIndividual = (index: number) => {
    return (
      <animated.div
        key={index}
        style={{
          width: "8px",
          height: "30px",
          borderRadius: "15px",
          backgroundColor: "#ff5d00",
          transform: animate.to({
            range: [0, 0.1 * (index + 1), 1],
            output: [`scale(0.3)`, `scale(1)`, `scale(0.3)`],
          }),
        }}
      />
    );
  };

  useEffect(() => {
    for (let i = 0; i < 5; i++) {
      if (i === 0) {
        setWaveArray([waveIndividual(i)]);
      } else {
        setWaveArray((p) => [...(p as JSX.Element[]), waveIndividual(i)]);
      }
    }
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // background: "black",
        alignItems: "center",
        justifyContent: "space-evenly",
        width: "60%",
      }}
    >
      {waveArray}
    </div>
  );
};
