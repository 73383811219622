import React, { useContext } from "react";
import MotionElement from "../MotionElement/MotionElement";
import { Typography } from "@mui/material";
import { WindowDimensions } from "../../App";
import "../../screens/HomePage/HomePage.css";
import Slots from "./Slots";
import { useStoreState, IBooking } from "../../custom/store/store";
import { styles } from "./TimeSlot.styles";
import { fixedSlots } from "../../custom/constants";

interface ISelect {
  variant: string;
  bookedSlots?: IBooking[];
}

const TimeSlot = ({ variant, bookedSlots }: ISelect) => {
  const windowDimensions = useContext(WindowDimensions);
  const bookDetails = useStoreState((state) => state.bookDetails);
  let dateNow = new Date();

  const renderSlots = (time: string) => {
    let booked = 0;
    let privateSlot = false;
    try {
      bookedSlots?.forEach((slot) => {
        if (slot.date === bookDetails.date && slot.pool === "private") {
          slot.timeArr.forEach((val) => {
            if (val.time === time) {
              privateSlot = true;
            }
          });
        }
      });
      bookedSlots?.forEach((slot) => {
        if (slot.date === bookDetails.date && slot.pool === bookDetails.pool) {
          slot.timeArr.forEach((val) => {
            if (val.time === time) {
              booked = val.slots;
            }
          });
        }
      });
    } catch (e) {
      // pass
    }
    let slotArr = [];
    if (privateSlot) {
      for (let i = 0; i < fixedSlots; i++) {
        slotArr.push(
          <Slots key={i} variant={variant} time={time} status="unavailable" />
        );
      }
      return slotArr;
    }

    if (booked === 0) {
      for (let i = 0; i < fixedSlots; i++) {
        slotArr.push(
          <Slots key={i} variant={variant} time={time} status="available" />
        );
      }
      return slotArr;
    }
    for (let i = 0; i < booked; i++) {
      slotArr.push(
        <Slots key={i} variant={variant} time={time} status="booked" />
      );
    }
    for (let i = booked; i < fixedSlots; i++) {
      slotArr.push(
        <Slots key={i} variant={variant} time={time} status="available" />
      );
    }

    return slotArr;
  };

  const getTimeIndex = () => {
    if (
      `${dateNow.getDate()}/${
        dateNow.getMonth() + 1
      }/${dateNow.getFullYear()}` === bookDetails.date &&
      dateNow.getHours() >= 11
    ) {
      return dateNow.getHours() + 1;
    }
    return 11;
  };

  const getGridColumns = (display: string) => {
    let cols = "auto";
    if (display === "mobiles") {
      for (let i = 0; i < fixedSlots / 2 - 1; i++) {
        cols = cols.concat(" auto");
      }
      return cols;
    }
    for (let i = 0; i < fixedSlots - 1; i++) {
      cols = cols.concat(" auto");
    }
    return cols;
  };

  const renderTimeFrame = () => {
    let timeFrameArr = [];
    for (let i = getTimeIndex(); i < 23; i++) {
      timeFrameArr.push(
        <div
          key={i}
          style={{
            ...styles.timeFrameDiv,
            width: windowDimensions.width < 500 ? "90vw" : "700px",
          }}
        >
          <Typography style={styles.typography}>
            {`${i > 12 ? i - 12 : i}:00 ${i >= 12 ? "PM" : "AM"}`}
          </Typography>
          <div
            style={{
              ...styles.timeFrameColumn,

              gridTemplateColumns:
                windowDimensions.width < 500
                  ? getGridColumns("mobiles")
                  : getGridColumns("web"),
            }}
          >
            {renderSlots(`${i > 12 ? i - 12 : i}:00 ${i >= 12 ? "PM" : "AM"}`)}
          </div>
        </div>
      );
      timeFrameArr.push(
        <div
          key={i + 0.5}
          style={{
            ...styles.timeFrameDiv,
            width: windowDimensions.width < 500 ? "90vw" : "700px",
          }}
        >
          <Typography style={styles.typography}>
            {`${i > 12 ? i - 12 : i}:30 ${i >= 12 ? "PM" : "AM"}`}
          </Typography>
          <div
            style={{
              ...styles.timeFrameColumn,
              gridTemplateColumns:
                windowDimensions.width < 500
                  ? getGridColumns("mobiles")
                  : getGridColumns("web"),
            }}
          >
            {renderSlots(`${i > 12 ? i - 12 : i}:30 ${i >= 12 ? "PM" : "AM"}`)}
          </div>
        </div>
      );
    }
    return timeFrameArr;
  };

  return (
    <MotionElement
      duration={1}
      hide={{ opacity: 0, x: 100 }}
      show={{ opacity: 1, x: 0 }}
      // reverse={reverse}
    >
      <div className="horizontalScroll" style={styles.timeSlotDiv}>
        {renderTimeFrame()}
      </div>
    </MotionElement>
  );
};
export default TimeSlot;
