/* eslint-disable react-hooks/exhaustive-deps */

import { Typography } from "@mui/material";

import { styles } from "../../screens/BookingPage/Booking.styles";

interface IMultiline {
  multilineText: string[];
  autoClose?: number;
  fontSize?: string;
  textAlign?: "center" | "left" | "right";
}

const MultilineDescription = ({
  multilineText,
  fontSize = "1rem",
  textAlign = "center",
}: IMultiline) => {
  return (
    <>
      {multilineText.map((text) => (
        <Typography
          style={{
            ...styles.typography,
            fontSize,
            textAlign,
          }}
        >
          {text}
        </Typography>
      ))}
    </>
  );
};

export default MultilineDescription;
