/* eslint-disable react-hooks/exhaustive-deps */

import { TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import "./InputField.css";

interface IInput {
  type?: string;
  value?: string;
  name?: string;
  placeholder?: string;
  onChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  autoClose?: number;
  maxLength?: number;
  error?: boolean;
}
const useStyles = makeStyles({
  root: {
    // input label when focused
    "& label.Mui-focused": {
      color: "black",
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "black",
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
});

const InputField = ({
  type,
  placeholder,
  onChange,
  value,
  maxLength,
  error,
}: IInput) => {
  const classes = useStyles();
  return (
    <div
      style={{
        marginTop: "20px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TextField
        id="standard-basic"
        label={placeholder}
        variant="standard"
        className={classes.root}
        value={value}
        onChange={onChange}
        type={type}
        inputProps={{ maxLength }}
        error={error}
      />
    </div>
  );
};

export default InputField;
