type IMethod = "GET" | "POST" | "PUT" | "DELETE";
type IRequest = (url: string, method: IMethod, body?: any) => Promise<any>;

const request: IRequest = (url, method = "GET", body) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export default request;
