/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { Typography } from "@mui/material";
import { motion, useAnimation } from "framer-motion";
import labels from "./Booking.labels.json";
import { isMobile } from "react-device-detect";
import Footer from "../../components/Footer/Footer";
import MotionElement from "../../components/MotionElement/MotionElement";
import { styles, motionStyles } from "./Booking.styles";
import DateSelect from "./subComponents/DateSelect";
import { WindowDimensions } from "../../App";
import { useStoreActions, useStoreState } from "../../custom/store/store";

export default function Booking() {
  const windowDimensions = useContext(WindowDimensions);
  const setSelectedSlots = useStoreActions(
    (actions) => actions.setSelectedSlots
  );
  const bookDetails = useStoreState((state) => state.bookDetails);
  const setBookDetails = useStoreActions((actions) => actions.setBookDetails);

  const [selected, setSelected] = useState<"private" | "common" | "none">(
    "none"
  );

  const mainControls = useAnimation();

  useEffect(() => {
    selected !== "none" && mainControls.start("selected");
    setSelectedSlots(0);
  }, [selected]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <motion.div
        variants={{
          hidePage: { opacity: 0 },
          showPage: { opacity: 1 },
        }}
        initial="hidePage"
        animate="showPage"
        transition={{ duration: 1, delay: 0 }}
        style={motionStyles.content}
      >
        <Typography
          style={{ ...styles.heading, position: "absolute", top: "3%" }}
        >
          {labels.choose}
        </Typography>
        <div
          style={{
            height: selected !== "none" ? "20vh" : "fit-content",
          }}
        >
          <MotionElement
            duration={1}
            delay={0.6}
            hide={{ opacity: 0, scale: 0.5 }}
            show={{ opacity: 1, scale: 1 }}
            // reverse={reverse}
          >
            <motion.div
              whileHover={
                selected === "none"
                  ? {
                      scale: isMobile ? 0.9 : 1.2,
                    }
                  : { scale: isMobile ? 0.4 : 0.55 }
              }
              variants={{
                initial: {
                  scale: selected === "none" ? 1 : 0.5,
                },
                selected: {
                  scale: 0.5,
                  x: -100,
                  y: -50,
                  backgroundColor:
                    selected === "private"
                      ? ["hsl(0, 0, 96)", "hsl(0, 0, 90)"]
                      : ["hsl(0, 0, 90)", "hsl(0, 0, 96)"],
                },
              }}
              initial="initial"
              animate={mainControls}
              transition={{
                type: "spring",
                bounce: 0.2,
              }}
              style={{
                ...motionStyles.card,
                width:
                  windowDimensions.width < 420
                    ? "80vw"
                    : `${windowDimensions.width / 6}px`,
                cursor: isMobile ? "default" : "pointer",
                marginTop: "25%",
              }}
              onClick={() => {
                setSelected("private");
                setBookDetails({ ...bookDetails, pool: "private" });
              }}
            >
              <Typography
                style={{
                  ...styles.heading,
                }}
              >
                {labels.private}
              </Typography>
              {selected === "none" && (
                <Typography
                  style={{
                    ...styles.typography,
                  }}
                >
                  {labels.privateDescription}
                </Typography>
              )}
              {selected === "none" && (
                <Typography
                  style={{
                    ...styles.typography,
                  }}
                >
                  {labels.playerCountPrivate}
                </Typography>
              )}
            </motion.div>
          </MotionElement>
          <MotionElement
            duration={1}
            delay={0.7}
            hide={{ opacity: 0, scale: 0.5 }}
            show={{ opacity: 1, scale: 1 }}
            // reverse={reverse}
          >
            <motion.div
              whileHover={
                selected === "none"
                  ? { scale: isMobile ? 0.9 : 1.2 }
                  : { scale: isMobile ? 0.4 : 0.55 }
              }
              variants={{
                initial: { scale: selected === "none" ? 1 : 0.5 },
                selected: {
                  scale: 0.5,
                  x: 100,
                  y: -310,
                  backgroundColor:
                    selected === "common"
                      ? ["hsl(0, 0, 96)", "hsl(0, 0, 90)"]
                      : ["hsl(0, 0, 90)", "hsl(0, 0, 96)"],
                },
              }}
              initial="initial"
              animate={mainControls}
              transition={{
                type: "spring",
                bounce: 0.2,
              }}
              style={{
                ...motionStyles.card,
                width:
                  windowDimensions.width < 420
                    ? "80vw"
                    : `${windowDimensions.width / 6}px`,
                margin: "25% 0% 20px 0%",
                cursor: isMobile ? "default" : "pointer",
              }}
              onClick={() => {
                setSelected("common");
                setBookDetails({ ...bookDetails, pool: "common" });
              }}
            >
              <Typography
                style={{
                  ...styles.heading,
                }}
              >
                {labels.common}
              </Typography>
              {selected === "none" && (
                <Typography
                  style={{
                    ...styles.typography,
                  }}
                >
                  {labels.commonDescription}
                </Typography>
              )}
              {selected === "none" && (
                <Typography
                  style={{
                    ...styles.typography,
                  }}
                >
                  {labels.playerCountCommon}
                </Typography>
              )}
            </motion.div>
          </MotionElement>
        </div>
        {selected !== "none" && <DateSelect selected={selected} />}
      </motion.div>
      <Footer />
    </>
  );
}
