import { MotionStyle } from "framer-motion";
import React from "react";

interface IMotionStyles {
  [x: string]: MotionStyle;
}

interface IStyles {
  [x: string]: React.CSSProperties;
}

export const motionStyles: IMotionStyles = {};

export const styles: IStyles = {
  timeFrameDiv: {
    // background: "green",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "2%",
  },
  timeFrameColumn: {
    width: "80%",
    display: "inline-grid",
    justifyItems: "center",
    rowGap: "15%",
  },
  typography: {
    fontSize: `1rem`,
    color: "ghostwhite",
    fontFamily: "jost",
    margin: "10px 0% 10px 0%",
    background: "#2d2d2c",
    padding: "0% 1% 0% 1%",
    borderRadius: "3px",
    textAlign: "center",
  },
  timeSlotDiv: {
    height: "30vh",
    overflowY: "scroll",
    background: "black",
    paddingBottom: "5%",
    borderRadius: "1rem",
  },
  slots: {
    height: "28px",
    borderRadius: ".3rem",
    margin: "1%",
    cursor: "pointer",
  },
  privatePoolDiv: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
};
