import { createStore, action, Action, createTypedHooks } from "easy-peasy";

export interface IBooking {
  pool: string;
  slots: number;
  timeArr: ITime[];
  date: string;
}

export interface ITime {
  time: string;
  slots: number;
}

interface IStore {
  bookDetails: IBooking;
  setBookDetails: Action<IStore, IBooking>;
  selectedSlots: number;
  setSelectedSlots: Action<IStore, number>;
  bookPageLoad: boolean;
  setBookPageLoad: Action<IStore, boolean>;
}

export const store = createStore<IStore>({
  bookDetails: {
    pool: "",
    slots: 0,
    timeArr: [],
    date: "",
  },
  setBookDetails: action((state, payload) => {
    state.bookDetails = payload;
  }),
  selectedSlots: 0,
  setSelectedSlots: action((state, payload) => {
    if (payload === 0) {
      state.selectedSlots = 0;
    }
    state.selectedSlots += payload;
    if (state.selectedSlots < 0) {
      state.selectedSlots = 0;
    }
  }),
  bookPageLoad: false,
  setBookPageLoad: action((state, payload) => {
    state.bookPageLoad = payload;
  }),
});

const typedHooks = createTypedHooks<IStore>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;
